import gql from 'graphql-tag';

export const ADMIN_QUERY = gql`
  query me {
    profile {
      id
      email
      role
    }
  }
`;

export const CLINIC_QUERY = gql`
  query me {
    profile {
      id
      email
      role
      isDeleted
      active
      isActive
      isBlocked
      isPending
      isTrial
      isExpired
      isCanceled
      name
      signed
      phone
      address
      isNoLimitDoctors
      isPatientUnlimitedAccess
      isDoctorUnlimitedAccess
      maxDoctors
      isNoLimitPatients
      maxPatients
      enableNotification
      nextPaymentDate
      isActiveInvitesBefore
      invitesBefore
      fee {
        basic
      }
      clinicAdmin {
        firstName
        lastName
        title
      }
      avatarUrl
    }
  }
`;

export const PATIENT_QUERY = gql`
  query me {
    profile {
      id
      email
      firstName
      lastName
      role
      enableNotification
      dob
      signed
      medicalID
      phone
      active
      isBlocked
      isCanceled
      isPending
      isActive
      isTrial
      fee {
        basic
      }
      additionalInfo
      isNewsSubscribed
      totalTestResults
      totalSsiQuestionnaireResults
      avatarUrl
      clinics {
        id
        name
        active
        plan {
          planId
          price
        }
      }
      doctors {
        id
        position
        firstName
        lastName
        active
        avatarUrl
        plan {
          planId
          price
        }
      }
      procedures {
        createdAt
        label
        id
        assignedBy {
          id
          email
        }
        timestamp
      }
      medicines {
        id
        createdAt
        medicine {
          label
          groupName
        }
        assignedBy {
          id
          email
        }
      }
      promoCode
      plan {
        planId
        price
      }
    }
    procedures {
      id
      label
    }
    medicines {
      id
      label
      groupName
    }
  }
`;

export const DOCTOR_QUERY = gql`
  query me {
    profile {
      id
      email
      firstName
      lastName
      phone
      specialty
      address
      webSite
      maxPatients
      clinicName
      role
      enableNotification
      isNewsSubscribed
      signed
      fee {
        basic
      }
      position
      invitesBefore
      active
      isActive
      isBlocked
      isCanceled
      isPending
      isTrial
      isExpired
      avatarUrl
      clinics {
        id
        name
        active
      }
    }
    procedures {
      id
      label
    }
    medicines {
      id
      label
      groupName
    }
  }
`;

export const UNIVERSAL_USER_QUERY = gql`
  query me {
    profile {
      manualSubscribedTillDate
      isManualSubscribed
      id
      email
      firstName
      lastName
      role
      position
      dob
      enableNotification
      medicalID
      phone
      active
      additionalInfo
      avatarUrl
      doctors {
        id
        position
        firstName
        lastName
        active
        avatarUrl
      }
      patients {
        id
        signed
        email
        additionalInfo
        firstName
        lastName
        dob
        medicalID
        phone
        role
        active
        avatarUrl
      }
    }
  }
`;


export const GET_USER_FREE_TRIAL_DETAILS = gql`
  query me {
    profile {
      id
      manualSubscribedTillDate
      isManualSubscribed
    }
  }
`;
